<template>
	<div id="aapp">
		<div class="container">
			<div class="left">
				<el-input placeholder="输入关键字进行搜索..." prefix-icon="el-icon-search" v-model="filterText"
					style="margin: 10px 10px 5px 0; width: 100%">
				</el-input>
				<div class="left-content-top">
					<div class="type-title">
						<span class="title">单位列表</span>
					</div>
					<dv-decoration-1 style="width:300px;height:15px;" :color="['lightblue']" />
					<el-tree :check-strictly="true" :data="companyList" :props="defaultProps" :default-expand-all="true"
						node-key="id" :filter-node-method="filterNode" :expand-on-click-node="false" show-checkbox
						@check="getPickCompant" ref="tree">
						<span class="custom-tree-node" slot-scope="{ node, data }">
							<span :title="node.label">{{ node.label }}</span>
						</span>
					</el-tree>
				</div>
			</div>
			<div class="right">
				<div>
					<el-row>
						<el-col :span="3" class="rows">
							<el-input placeholder="输入上报人员姓名" prefix-icon="el-icon-search" v-model="reportName">
							</el-input>
						</el-col>
						<el-col :span="3" class="rows">
							<el-select v-model="status" style="width: 100%;" placeholder="请选择处理状态"
								@change="selectStatus" clearable>
								<el-option v-for="item in statusList" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-col>
						<el-col :span="6" class="rows">
							<el-date-picker v-model="time" type="datetimerange" @change="pickedTimes"
								start-placeholder="开始日期" end-placeholder="结束日期" clearable :default-time="['12:00:00']">
							</el-date-picker>
						</el-col>
						<el-col :span="6" class="rows">
							<el-button type="primary" plain size="small" @click="inquiry">查询</el-button>
						</el-col>
						<el-col :span="6" class="rows" style="float:right;width:12%;margin: 10px 20px">
							<el-select v-model="tableTitle" multiple collapse-tags placeholder="选择类型"
								@change="selectTitle">
								<el-option v-for="item in tableTitleList" :key="item.value" :label="item.title"
									:value="item.title">
								</el-option>
							</el-select>
						</el-col>
					</el-row>
				</div>
				<div class="content">
					<div class="type-title">
						<span class="title">巡检巡查信息</span>
						<dv-decoration-3 style="width:260px;height:10px;" />
					</div>
					<el-table :data="inspectionTourList" :max-height="700" style="width: 100%;cursor:pointer"
						ref="table" highlight-current-row>
						<el-table-column fixed="left" type="index" label="序号" width="55" align="center">
						</el-table-column>
						<el-table-column v-if="tableTitle.includes('上报人姓名')" prop="reportUserName" label="上报人姓名"
							align="center">
						</el-table-column>
						<el-table-column v-if="tableTitle.includes('领取人姓名')" prop="receiveUserName" label="领取人姓名"
							align="center">
						</el-table-column>
						<el-table-column v-if="tableTitle.includes('问题名称')" prop="problemName" label="问题名称"
							align="center">
						</el-table-column>
						<el-table-column v-if="tableTitle.includes('问题描述')" prop="problemDescribe" label="问题描述"
							align="center">
						</el-table-column>
						<el-table-column v-if="tableTitle.includes('单位')" prop="departmentId" label="单位" align="center">
						</el-table-column>
						<el-table-column v-if="tableTitle.includes('状态')" label="状态" align="center" prop="status">
							<template slot-scope="scope">
								<span>{{scope.row.status==0?'未领取':scope.row.status==1?'已领取':'已解决'}}</span>
							</template>
						</el-table-column>
						<el-table-column v-if="tableTitle.includes('上报时间')" prop="createTime" label="上报时间"
							align="center">
						</el-table-column>
						<el-table-column v-if="tableTitle.includes('截止时间')" prop="lastTime" label="截止时间" align="center">
						</el-table-column>
						<el-table-column v-if="tableTitle.includes('完成时间')" prop="completeTime" label="完成时间"
							align="center">
						</el-table-column>
						<el-table-column label="操作" fixed="right" align="center" width="80">
							<template slot-scope="scope">
								<el-button size="mini" type="primary" @click="details(scope.row)">详情</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div>
						<el-pagination :background="true" @current-change="pagination" :current-page.sync="current"
							:page-size="limit" layout="total, prev, pager, next , jumper" :total="total">
						</el-pagination>
					</div>
				</div>

				<el-dialog title="巡检巡查信息详情" :visible.sync="dialogVisible" width="30%"
					style="background:rgba(0,0,0,0.5)">
					<div class="dialog">
						<div class="item">
							<div style="width:50%">
								<div style="font-size:18px;margin:10px 0">处理图片 {{imageList1.length}} 张</div>
								<el-image :src="image1" :preview-src-list="imageList1" style="width:60%">
									<div slot="error" class="image-slot sort">
										<i class="el-icon-picture-outline"></i>
									</div>
								</el-image>
							</div>
							<div style="width:50%">
								<div style="font-size:18px;margin:10px 0">问题图片 {{imageList2.length}} 张</div>
								<el-image :src="image2" :preview-src-list="imageList2" style="width:60%">
									<div slot="error" class="image-slot sort">
										<i class="el-icon-picture-outline"></i>
									</div>
								</el-image>
							</div>
						</div>
					</div>
				</el-dialog>

			</div>
		</div>
	</div>
</template>

<script>
	import CompanyInfo from "@/api/managementApi/CompanyInfo";
	import companySystem from "@/api/managementApi/CompanyInfo";
	import BaseData from '@/assets/config/BaseData'

	// 记录接口
	import VisitorsToRecord from '@/api/facilitiesManagement/VisitorsToRecord'

	export default {
		name: 'VisitorsToRecord',
		data() {
			return {
				tableTitleList: [{
					id: 0,
					title: '上报人姓名',
				}, {
					id: 1,
					title: '领取人姓名',
				}, {
					id: 2,
					title: '问题名称',
				}, {
					id: 3,
					title: '问题描述',
				}, {
					id: 4,
					title: '单位',
				}, {
					id: 5,
					title: '状态',
				}, {
					id: 6,
					title: '上报时间',
				}, {
					id: 7,
					title: '截止时间',
				}, {
					id: 8,
					title: '完成时间',
				}],
				tableTitle: ['上报人姓名', '领取人姓名', '问题名称', '问题描述', '单位', '状态', '上报时间', '截止时间', '完成时间'],


				reportName: '', // 上报人姓名
				status: null, // 状态
				statusList: [{
					value: 1,
					label: '已领取',
				}, {
					value: 0,
					label: '未领取',
				}, {
					value: 2,
					label: '已完成',
				}],
				dialogVisible: false,
				dialogTitle: '',

				time: '',
				// 公司列表
				companyList: [],
				// 过滤查询
				filterText: '',
				// 公司树形结构绑定
				defaultProps: {
					children: "smallCompanyList", // 子公司节点
					label: "name", // 公司名
				},

				// 选择的公司
				checkedKeys: [],
				startTime: '',
				endTime: '',

				// 巡检巡查列表
				inspectionTourList: [],
				total: 0,
				limit: 10,
				current: 1,


				allCompanyList: [],

				image1: '',
				imageList1: [],
				image2: '',
				imageList2: [],


				tourCode: '',
			}
		},

		created() {
			this.init()
			setTimeout(() => {
				this.inquiry()
			}, 10);
		},
		mounted() {

		},
		watch: {
			filterText(val) {
				this.$refs.tree.filter(val);
			},
		},
		methods: {

			init() {
				this.getAllCompany()
				this.getCompanyStructure()
				this.getInspectionPatrolHistory();
			},
			selectTitle(value) {
				if (value.length < 1) {
					this.$message.warning("至少选择1个类型")
					this.tableTitle.push('上报人姓名')
				} else {
					this.tableTitle = value
				}
			},
			// 返回
			goBack() {
				this.$router.go(-1)
			},
			// 选择公司过滤器
			filterNode(value, data) {
				if (!value) return true;
				return data.name.indexOf(value) !== -1;
			},
			// 获取公司节点信息   (根据公司查全部设备)
			getPickCompant(value) {
				this.checkedKeys = this.$refs.tree.getCheckedKeys();
				console.log(this.checkedKeys);
				this.getInspectionPatrolHistory()
			},
			// 根据状态查询
			selectStatus(value) {
				this.status = value
			},
			// 分页
			pagination(params) {
				this.getInspectionPatrolHistory()
			},

			// 时间选择
			pickedTimes(value) {
				if (value == null) {
					this.startTime = '';
					this.endTime = '';
				} else {
					let startTime = this.$moment(value[0]).format('YYYY-MM-DD HH:mm:ss')
					let endTime = this.$moment(value[1]).format('YYYY-MM-DD HH:mm:ss')
					this.startTime = startTime;
					this.endTime = endTime;
				}
			},

			// 查询
			inquiry() {
				this.getInspectionPatrolHistory()
			},

			// 详情
			details(info) {
				console.log(info);
				this.dialogVisible = true;
				this.dialogTitle = info.reportUserName;
				this.imageList1 = [];
				this.imageList2 = [];
				// 处理图片
				if (info.checkImg1 != null) {
					this.imageList1.push(BaseData.wechatName + info.checkImg1)
				}
				if (info.checkImg2 != null) {
					this.imageList1.push(BaseData.wechatName + info.checkImg2)
				}
				if (info.checkImg3 != null) {
					this.imageList1.push(BaseData.wechatName + info.checkImg3)
				}

				if (info.problemImg1 != null) {
					console.log(1);
					this.imageList2.push(BaseData.wechatName + info.problemImg1)
				}
				if (info.problemImg2 != null) {
					console.log(2);
					this.imageList2.push(BaseData.wechatName + info.problemImg2)
				}
				if (info.problemImg3 != null) {
					console.log(3);
					this.imageList2.push(BaseData.wechatName + info.problemImg3)
				}

				// if (info.imageList1.length != 0) {
				//   this.image1 = info.imageList1[0];
				// }
				this.image1 = BaseData.wechatName + info.checkImg1
				console.log(this.image1);
				console.log(this.imageList1);

				console.log('----------------');

				// if (info.imageList2.length != 0) {
				//   this.image2 = info.imageList2[0];
				// }
				this.image2 = BaseData.wechatName + info.problemImg1
				console.log(this.image2);
				console.log(this.imageList2);


			},


			// 查所有公司结构
			async getCompanyStructure() {
				const {
					data: res
				} = await CompanyInfo.getAllCompanyStructure()
				this.companyList = res.data.list;
				console.log(this.companyList);

			},
			// 所有公司
			async getAllCompany() {
				const {
					data: res
				} = await companySystem.getCompanyInfo()
				this.allCompanyList = res.data.list
			},
			// 巡检巡查记录
			async getInspectionPatrolHistory() {
				const {
					data: res
				} = await VisitorsToRecord.getInspectionPatrolHistory(this.limit, this.current, this.checkedKeys, this
					.startTime, this.endTime, this.reportName, this.status)
				this.inspectionTourList = res.data.list;
				console.log(this.inspectionTourList);
				this.total = res.data.total
				for (const item of this.inspectionTourList) {
					if (item.completeTime == null) {
						item.completeTime = '—'
					}
					if (item.receiveUserName == '' || item.receiveUserName == null) {
						item.receiveUserName = '—'
					}
					for (let i = 0; i < this.allCompanyList.length; i++) {
						if (item.departmentId == this.allCompanyList[i].id) {
							item.departmentId = this.allCompanyList[i].name
						}
					}
				}
			},
			// 所有公司
			async getAllCompany() {
				const {
					data: res
				} = await companySystem.getCompanyInfo()
				this.allCompanyList = res.data.list;
			}
		}
	}
</script>

<style lang="scss" scoped>
	// UI默认样式
	@import "@/assets/css/uiCss.scss";

	.rows {
		margin: 10px 10px 10px 0;
	}

	.elcol {
		margin-right: 5px;
	}

	.container {
		box-sizing: border-box;
		width: 100%;
		height: 89vh;
		display: flex;

		.left {
			width: 17.7%;
			height: 95%;
			box-sizing: border-box;
			margin: 0 15px;

			.left-content-top {
				border-radius: 10px;
				margin-top: 5px;
				width: 100%;
				height: 98%;
				overflow: scroll;
				background: rgba(1, 65, 70, 0.2);
				scrollbar-width: none;
			}
		}

		//   模块标题
		.type-title {
			margin: 10px;

			.title {
				font-family: "宋体";
				font-size: 20px;
				font-weight: 600;
				color: #0cc7ce;
				letter-spacing: 2px;
			}

			.title::before {
				content: "丨";
				font-weight: 600;
				font-size: 20px;
			}
		}

		.right {
			height: 100%;
			width: 100%;

			.content {
				border: 1px;
				position: relative;
				box-sizing: border-box;
				padding: 0px 10px 0 0;
				height: 93%;
				margin: 10px 0;
				border-radius: 10px;
				background: rgba(1, 65, 70, 0.2);
			}
		}
	}

	.dialog {
		height: 260px;
		overflow: scroll;
		display: flex;

		.item {
			// margin: 10px;
			width: 100%;
			// height: 385px;
			display: flex;
			// box-shadow: 2px 2px 5px #ccc;

			.demonstration {
				display: inline-block;
				width: 100%;
				text-align: center;
				font-size: 18px;
				font-weight: 600;
				letter-spacing: 3px;
				margin: 10px 0;
			}
		}
	}

	// 表格和树形图hover

	/deep/.el-table__row:hover {
		color: #72d4dc;
	}

	/deep/.el-table__body .el-table__row.hover-row td {
		background-color: rgba($color: #007a91, $alpha: 0.5) !important;
	}

	/deep/.el-tree-node__content:hover {
		background-color: rgba($color: #007a91, $alpha: 0.5) !important;
		color: #72d4dc;
	}
</style>